import { CssBaseline, MuiThemeProvider, NoSsr } from "@material-ui/core";
import "./App.css";
import { responsiveFontSizes } from "@material-ui/core/styles";
import Theme from "./theme/theme";
import NavigationRoutesUser from "./routes/NavigationRoutesUser";
import ConnectionStatus from "./components/HealthCheck/ConnectionStatus";
import ErrorBoundry from "./components/Error/ErrorBoundry";
import Maintenance from "./components/Maintencance/Maintenance ";
function App() {
  let theme = Theme;
  theme = responsiveFontSizes(theme);
  const isUnderMaintenance = process.env.REACT_APP_IS_UNDER_MAINTENANCE;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <NoSsr>
        <ErrorBoundry>
          <ConnectionStatus />
          {isUnderMaintenance === "true" ? (
            <Maintenance />
          ) : (
            <NavigationRoutesUser />
          )}
        </ErrorBoundry>
      </NoSsr>
    </MuiThemeProvider>
  );
}

export default App;
