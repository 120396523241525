import React from "react";
import PropTypes from 'prop-types';
//material ui import
import { makeStyles, Button as MuiButton, CircularProgress, Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

//CSS STYLES
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth:'0',
   minHeight:'35.5px',   
    padding:'5px 15px',
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    '& .MuiSvgIcon-root':{
      color:"inherit",
      
    },
  
  },
  wrapper: {
   
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const Button = (props) => {
  const classes = useStyles();
  const { label, color, variant, onClick, className, size, isFullwidth,title, isProgress=false, ...other } = props;
 
  return (
    <div className={classes.wrapper}>
  {title ? <Tooltip title={title} aria-label={title} arrow>
    <span>
    <MuiButton
      className={classes.root + " " + (className || "")}
      variant={variant || 'outlined'}
      color={color || "primary"}
      size={size || "medium"}
      onClick={onClick}
      {...other}
      fullWidth={isFullwidth || false}
     disabled={isProgress}
     disableElevation
    >
     {label || props.children}
    </MuiButton>
    </span>
  </Tooltip> :
    <MuiButton
    className={classes.root + " " + (className || "")}
    variant={variant || 'outlined'}
    color={color || "primary"}
    size={size || "medium"}
    onClick={onClick}
    {...other}
    fullWidth={isFullwidth || false}
   disabled={isProgress}
  >
   {label || props.children}
  </MuiButton>
  }
     {isProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
Button.propTypes={
  label: PropTypes.string,
  title: PropTypes.string,
  color:PropTypes.string,
  isProgress:PropTypes.bool,
  variant:PropTypes.oneOf(['contained','outlined','text']),
  onClick:PropTypes.func,
  className:PropTypes.string,
  size:PropTypes.string,
  isFullwidth:PropTypes.bool
};
export default Button;
