import React from 'react'
import { Snackbar, makeStyles, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        top: theme.spacing(9),
        // '& .MuiAlert-root': {
        //     backgroundColor: '#f3b33d',
        //     color: '#000'
        // },
        // '& .MuiAlert-icon': {
        //     color: '#000'
        // }
         '& .MuiAlert-message': {
         textTransform:"capitalize",
        }
    }
}))

export default function Notification(props) {

    const { info, setInfo} = props;
    const classes = useStyles()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInfo({
            ...info,
            isOpen: false
        })
    }

    return (
        <Snackbar
           // autoHideDuration={50000}
            className={classes.root}
            open={info.isOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
           
            onClose={handleClose}>
            <Alert severity={info.type}
                onClose={handleClose}>
                {info.message}
            </Alert>
        </Snackbar>
    )
}