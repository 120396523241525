import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
 
  Divider,
} from "@material-ui/core";
import ActionButton from "../Button/ActionButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    paddingTop: "0px ",
    position: "absolute",
    top: theme.spacing(1),
    display: "flex",
    minWidth: "280px",
    //minHeight: "200px",
   
  },
  dialogTitle: {
    background: "#fff",
    color: theme.palette.text.primary,
    padding: "15px 12px 12px 12px",
    textTransform: "upperCase",
  },
}));

export default function Popup(props) {
  const { title, children,maxWidth, openPopup, setOpenPopup, fullWidth, ...others } =
    props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth || 'sm'}
      fullWidth={true}
      fullScreen={false}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" component="h6" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <ActionButton
            style={{ color: "#303E67", margin: "0px", border: "none" }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </ActionButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
