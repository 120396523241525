import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { headerHeight } from "../../utils/Constant/constant";
const useStyles = makeStyles((theme) => ({
  root: {
    height: headerHeight,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
const HeaderBar = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <AppBar className={classes.root} square elevation={0} color={"secondary"}>
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
