import React from 'react';
import PropTypes from 'prop-types';
import { useLocation,Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
const AuthRequire = ({allowedRoles=[], allowedPermissions=[]}) => {
    const {auth}=useAuth();
    const location=useLocation();
    const hasRoles=allowedRoles?.length>0;
    const hasPermission=allowedPermissions?.length>0;
    const rolePermitted=  auth?.roles?.find(role=>allowedRoles?.includes(role));
const permissionPermitted=auth?.permissions?.find(permission=>allowedPermissions.includes(permission))
  //  const allowed=(allowedPermissions?.length>0 && allowedRoles?.length>0) ? (rolePermitted && permissionPermitted)
  //  : ;
   const allowed= (hasRoles && hasPermission )
                  ? (rolePermitted && permissionPermitted)
                  : (hasRoles && !hasPermission)
                  ?rolePermitted
                  : (!hasRoles && permissionPermitted)
                  ?permissionPermitted
                  :false;

  return (
    allowed 
    ? <Outlet/>
     :auth?.userName
    ?<Navigate to="/unauthorised" state={{ from:location}} replace/>
    :<Navigate to="/login" state={{ from:location}} replace/>
      );
};
AuthRequire.propTypes={
  allowedRoles:PropTypes.array,
  allowedPermissions:PropTypes.array
  
};
export default AuthRequire;
