import jwtDecode from 'jwt-decode';
import React from 'react'

const getUserSessionData = () => {
     //get sessionId from session
  const sessionId =sessionStorage.getItem("ars-audit-session");
   //get access token from session storage
   const accessToken =sessionStorage.getItem(sessionId);
   //decoded token
   const decodedToken = accessToken ? jwtDecode(accessToken) : null;
   //get userProfileImage from session storage
   const userProfileImageSessionKey = `${sessionId}-ProfileImage`;
   const userProfileImage =sessionStorage.getItem(userProfileImageSessionKey);
   //get userCompanyImage from session storage
   const userCompanyImageSessionKey = `${sessionId}-UserCompanyImage`;
   const userCompanyImage =sessionStorage.getItem(userCompanyImageSessionKey);
   const userName = decodedToken?.userName;
   const roles = decodedToken?.role;
   const permissions = decodedToken?.Permissions;
   const companyName = decodedToken?.companyName;
   const userId = decodedToken?.userId;
  return  {
    userName,
    roles,
    permissions,
    accessToken,
    userProfileImage,
    userCompanyImage,
    companyName,
    userId
  };
}

export default getUserSessionData