export const permissions = {
  "CanCreateAudit":"CanCreateAudit",
  "CanViewAudit":"CanViewAudit",
  "CanDeactiveAudit":"CanDeactiveAudit",
  "CanUploadAuditDocuments":"CanUploadAuditDocuments",
  "CanAddManualCheckpoint":"CanAddManualCheckpoint",
  //  "CanCreateAuditCheckpoint":"CanCreateAuditCheckpoint",//no
    "CanViewAuditCheckpoint":"CanViewAuditCheckpoint",
    "CanAddManualObservation":"CanAddManualObservation",
    "CanCreateObservation":"CanCreateObservation",
    "CanViewObservation":"CanViewObservation",
    "CanDeactiveObservation":"CanDeactiveObservation",
  "CanCommentonPRNC":"CanCommentonPRNC",
  "CanFileUploadPRNC":"CanFileUploadPRNC",
  "CanDownloadExcelFileofPRNC":"CanDownloadExcelFileofPRNC",
 
  "CanCommentonCompliance":"CanCommentonCompliance",
  "CanFileUploadCompliance":"CanFileUploadCompliance",
  "CanDownloadExcelFileofCompliance":"CanDownloadExcelFileofCompliance",
  "CanDownloadAuditDocument":"CanDownloadAuditDocument",
  "CanDownloadFinalReportPPT":"CanDownloadFinalReportPPT",
   "CanDeleteAuditDocument":"CanDeleteAuditDocument",
   "CanUnFreezeAudit":"CanUnFreezeAudit",
   "CanDownloadExcelFileofObservations":"CanDownloadExcelFileofObservations",
   "CanDownloadExcelFileofCheckpoints":"CanDownloadExcelFileofCheckpoints"
};
