import React from "react";
import PropTypes from "prop-types";
import { Badge as MuiBadge, IconButton, withStyles } from "@material-ui/core";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const Badge = (props) => {
  const { badgecontentCount, color, children, isShowZero, maxCount, variant } =
    props;
    const StyledBadge = withStyles((theme) => ({
     
    }))(MuiBadge);
  return (
   
      // <StyledBadge badgeContent={4} color="secondary">
      //   {children}
      // </StyledBadge>
  
    <MuiBadge
      badgeContent={badgecontentCount}
      color={color || "default"}
      showZero={isShowZero || false}
      max={maxCount}
      variant={variant || "standard"}
    
    >
      {children}
    </MuiBadge>
  );
};
Badge.propTypes = {
  children: PropTypes.node.isRequired,
  badgecontentCount: PropTypes.any.isRequired,
  maxCount: PropTypes.any.isRequired,
  color: PropTypes.any,
  isShowZero: PropTypes.any,
  variant: PropTypes.any,
};
export default Badge;
