import React, { memo } from "react";
import Proptypes from 'prop-types';
import { makeStyles, Switch as MuiSwitch } from "@material-ui/core";
const useStyles=makeStyles((theme)=>({
root:{
    border:'0.5px solid',
    borderRadius:'4px',
    width:'54px',
    borderColor:theme.palette.primary.main,
    '& .MuiSwitch-thumb' :{
        width: '16px',
        height: '18px',
    },
},
disabled:{
    borderColor:'#9c9c9d',
}
}));
const Switch = (props) => {
    const classes=useStyles();
  const { isChecked, name, onChangeHandler } = props;
 
  return (
    <MuiSwitch
    //className={`${classes.root} ${!isChecked && classes.disabled}`}
      checked={isChecked}
      onChange={(e)=>onChangeHandler()}
      name={name || "checkedSwitch"}
      color="primary"
      inputProps={{ 'aria-label': name }} 
     size="small"
    ></MuiSwitch>
  );
};
Switch.propTypes={
    name:Proptypes.string,
    isChecked:Proptypes.bool,
    onChangeHandler:Proptypes.func
};
export default memo(Switch);
