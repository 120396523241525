import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
const useStyles=makeStyles(theme=>({
    contentWrapper: {
        
        margin: "0 auto",
        paddingTop: "5px",
        paddingBottom: "5px",
        display:'flex',
        flexWrap:'wrap',
        justifyItems:'center',
        
      },
}))
const Container = (props) => {
    const classes=useStyles();
    const {children, cssStyle}=props;
    return (
        <div className={classes.contentWrapper +" "+ cssStyle}>
            {children}
        </div>
    )
};

Container.prototypes={
    children:PropTypes.any.isRequired
};
export default Container
