import React from 'react'
import {Paper, Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import Button from '../Button/Button';
import InfoIcon from '@material-ui/icons/Info';
import Draggable from 'react-draggable';

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        textTransform:"capitalize",
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.error.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: "#fff",
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    }
}))

export default function ConfirmPrompt(props) {

    const { confirmDialog, setConfirmDialog,isProgress, promptLabel="" } = props;
    const classes = useStyles()
const btnLabel=promptLabel!=="" ?promptLabel:"Connecting...";
 //generate unique id for dialog , exclude special char from title
 const titleId= promptLabel ? promptLabel?.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_') : 'draggable-dialog-title';
 const dialogId=titleId? titleId:"draggable-dialog-title";
function PaperComponent(props) {
    return (
      <Draggable handle={dialogId} cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }} PaperComponent={PaperComponent}
        aria-labelledby={dialogId}>
            <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id={dialogId}>
                <IconButton disableRipple className={classes.titleIcon}>
                 <InfoIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    label={btnLabel}
                   isProgress={isProgress}
                    onClick={() => setConfirmDialog()} />
             
            </DialogActions>
        </Dialog>
    )
}