import {  FormControl, FormLabel, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "../../controls";
import PropTypes from 'prop-types';
import '../Input/input.css';
const useStyles = makeStyles((theme) => ({
  controlLabel:{
    lineHeight:'100%',
    fontWeight:'500',
  },
  root: {
    border: "0.5px solid",
    borderColor:'rgba(0, 0, 0, 0.23)',
    borderRadius:'4px',
    display: "flex",
    flex: "wrap",
    fontSize: "14px",
    height:'40px',
    
    "& .MuiButton-root": {
      borderRadius: "0px",
      borderTopLeftRadius:'4px',
      borderBottomLeftRadius:'4px',
      height:'39px',
    },
    '& hover':{
      borderColor:'#303E67',
    },
  },

  input: {
    display: "none",
  },
  filenameText: {
    padding: "5px",
    width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const UploadButton = (props) => {
  const {label, prompt, selectedFile, onChange, name}=props;
  const classes = useStyles();
  // const [selectedFile, setSelectedFile] = useState("No File Choosen");
  // const fileChangeHandler = (e) => {
    
  //   setSelectedFile(e.target.files[0]["name"]);
  // };
  return (
    <FormControl component="fieldset">
    {prompt && (
      <Typography className={classes.controlLabel} component={"label"} htmlFor={'contained-button-file'} variant="subtitle1"
    
       >           
        {prompt}
      </Typography>
    
    )}
    <div className={`formControl ${classes.root}`}>
  
      <input
        accept="image/*"
        className={classes.input}
        id={name}
        name={name}
        type="file"
        onChange={onChange}
      />
      <label htmlFor={name}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          label={label}
         
         // startIcon={<AttachmentIcon />}
        ></Button>
      </label>
      <span className={classes.filenameText}>{selectedFile}</span>
    </div>
    </FormControl>
  );
};
UploadButton.propTypes={
  label:PropTypes.string.isRequired,
  prompt:PropTypes.string,
  selectedFile:PropTypes.string.isRequired,
  onChange:PropTypes.func.isRequired
}
export default UploadButton;
