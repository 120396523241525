import { Typography,makeStyles } from '@material-ui/core';
import React from 'react'
import {useNavigate} from 'react-router-dom';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import { Button } from '../../controls';
import HomeIcon from '@material-ui/icons/Home';
const useStyles=makeStyles((theme)=>({
    
        root:{
           display:'flex',
         
           flexWrap: "wrap",
           justifyContent: "center",
           justifyItems: "flex-start",
           flexDirection: "column",
           textAlign: "center",
    margin: "150px auto auto auto",
    textTransform:"capitalize",
    '& h2':{
        lineHeight:"200%",
    },
        },
        wrapper: {
            position: "relative",
            marginTop:"20px",
            
          },
          icon:{
            fontSize:'5rem',
            color:theme.palette.primary.main,
                }
        
}));

const PageNotFound = () => {
    const classes=useStyles();
    const navigate=useNavigate()
    const navigateToHome=()=>{
        navigate("/");
    }
  return (
    <div className={classes.root}>

<div>
<AnnouncementOutlinedIcon fontSize='large' className={classes.icon}/>
        <Typography variant="h2" component={"h2"}> The Request webpage does not exist</Typography>
        <Typography variant="h4" component={"h4"}> If you came upon this page bymistake, try checking the Url. </Typography>
</div>
  
   <div className={classes.wrapper}></div>
   <Button variant={"text"} onClick={navigateToHome} label="Click here to Revisit our website" startIcon={<HomeIcon/>} disableRipple/>
    </div>
  )
}

export default PageNotFound