//#region Popup Action Menu
////<summary>
////
////Custom Menu Used in various places in the app for Table Action Click Popup Menu
//// Material-ui Menu, IconButton, and Icons are used in this
//// take childe node as props  to render UI of Popup Action Menu
////<Summary
//#endregion Popup Action Menu
import React from "react";
import PropTypes from 'prop-types';
import { Menu, Fade, IconButton, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
const useStyles=makeStyles((theme)=>({
  root:{
    minHeight:'2rem',   
  padding:'5px 15px',
  },
  //MuiIconButton-root 
  //minHeight:'2rem',   
  //padding:'5px 15px',
  navLink: {
  
    textDecoration: "none",
   
  },
}));
const ActionMenu = (props) => {
  const classes=useStyles();
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
      className={classes.root}
        aria-controls="actionMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {children}
      </Menu>
    </>
  );
};
ActionMenu.prototype={
children:PropTypes.node.isRequired
};
export default ActionMenu;
