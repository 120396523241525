import React from 'react'
import { Table as MuiTable, makeStyles, TableContainer, Paper } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    root:{
        width: "100%",
        display:'flex',
        height:'100%',
        
    },
   table: {
   
    '& tbody tr:first-child td': {
        paddingTop:"7px",
     
   },
    },
}));

export default function StyledTable(props) {

    const classes = useStyles();

    return (
       
          <TableContainer className={classes.root} >
        <MuiTable className={classes.table} size="small">
            {props.children}
        </MuiTable>
        </TableContainer>
        
    )
}