import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import ConfirmPrompt from '../../controls/ConfirmDialog/ConfirmPrompt';
import useConnectivity from './useConnectivity'
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
const ConnectionStatus = () => {
    const {isOnline}=useConnectivity();
    const [confirmInfo, setConfirmInfo] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
      });
      useEffect(() => {
     const showstatus=()=>{
        setConfirmInfo({
            isOpen: true,
            title: "Internet Connectivity Lost",
            subTitle: "Please Reconnect and Try Again...",
          })
     }
     !isOnline && showstatus();
      
        return () => {
          setConfirmInfo({
            isOpen: false,
            title: "",
            subTitle: "",
          })
        }
      }, [isOnline])
      const handlePrompt=()=>{
isOnline && setConfirmInfo({
    isOpen: false,
    title: "",
    subTitle: "",
  })
      }
  return (
    confirmInfo && (
        <ConfirmPrompt
          confirmDialog={confirmInfo}
          setConfirmDialog={handlePrompt}
         isProgress={!isOnline}
        />
      )
  )
}

export default ConnectionStatus