import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import { Button } from "../../controls";
import HomeIcon from "@material-ui/icons/Home";
import BusyImg from "../../assets/images/maintenance.png";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fff",
    width: "100vw",
    height: "100vh",
    flexWrap: "wrap",
    justifyContent: "center",
    justifyItems: "flex-start",
    flexDirection: "column",
    textAlign: "center",
    // margin: "150px auto auto auto",
    textTransform: "capitalize",
    "& h2": {
        lineHeight: "200%",
        color:"#303e67",
        fontSize:"32px",
    },
    "& h4": {
        lineHeight: "200%",
        fontSize:"22px",
        color:"#666666",
      },
  },
  wrapper: {
    position: "relative",
    marginTop: "20px",
  },
  icon: {
    fontSize: "5rem",
    color: theme.palette.primary.main,
  },
}));

const Maintenance = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = document.title + 'Under Maintenance';
  }, []);
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };
  return (
    <div className={classes.root}>
      <div>
        <img src={BusyImg} alt="under maintenance" />
        <Typography variant="h2" component={"h2"}>
          {" "}
          Audit Review System
        </Typography>
        <Typography variant="h4" component={"h4"}>
          {" "}
          We are busy updating the site for you. It will be back in few minutes.
        </Typography>
      </div>
      <div></div>
      <div className={classes.wrapper}></div>
      <Button
        variant={"text"}
        onClick={navigateToHome}
        label="Try Again"
        startIcon={<HomeIcon />}
        disableRipple
      />
    </div>
  );
};

export default Maintenance;
