
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display:'flex',
        paddingTop:'20px',
         paddingBottom:'20px',
        textAlign:'left',
        "& .formControl": {
            margin: "5px 0px",
          },
        '& .MuiFormControl-root': {
            width:'100%',
       display:'flex',
        }
    }
}))

const Form=(props)=> {
    const classes = useStyles();
    const {autoComplete, children, ...other } = props;


    return (
        <form className={classes.root}  autoComplete={ autoComplete || "off"} {...other}>
            {children}
        </form>
    )
}
export default Form;