import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  navLink: {
    color: "black",
    textDecoration: "none",
   
  },
  selected: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  "& .Mui-selected": {
    backgroundColor: "red",
  },

  MuiListItemText: {
    color: "red",
  },
}));
const MenuItem = (props) => {
  const classes = useStyles();
  const {
       name,
    pathValue,
    icon = "",
    activeIcon = "",
    isIcon = false,isRoot=false,
    anchorIcon,
    
  } = props;
 const rootMenu=   <div style={{cursor:"context-menu"}}
   
 
>
 <ListItem component="li" >
   {icon !== null && <ListItemIcon>{<img src={icon} />}</ListItemIcon>}
{name && <ListItemText align="left" primary={name} /> }{anchorIcon}
   
 </ListItem>
</div>;
const subMenu=   <NavLink
   to={pathValue}
className={({ isActive }) =>
  isActive ? classes.selected : classes.navLink
}
>
<ListItem component="li" >
  {icon !== null && <ListItemIcon>{<img src={icon} />}</ListItemIcon>}
{name && <ListItemText align="left" primary={name} />}
  
</ListItem>
</NavLink>;
  return (
    subMenu
  );
};
MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  //pathValue: PropTypes,

  icon: PropTypes.any,
  activeIcon: PropTypes.any,
};

export default MenuItem;
