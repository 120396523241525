import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Paper } from '@material-ui/core'
import Button from '../Button/Button';
import InfoIcon from '@material-ui/icons/Info';
import Draggable from 'react-draggable';
const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        textTransform:"capitalize",
    },
    dialogTitle: {
        textAlign: 'center',
        cursor:'move',
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    }
}))

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()
     //generate unique id for dialog , exclude special char from title
 const titleId= confirmDialog?.title ? confirmDialog?.title?.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_') : 'draggable-dialog-title';
 const dialogId=titleId? titleId:"draggable-dialog-title";
    function PaperComponent(props) {
        return (
          <Draggable handle={dialogId} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
          </Draggable>
        );
      }
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }} 
        PaperComponent={PaperComponent}
        aria-labelledby={dialogId}
        >
            <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id={dialogId}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <InfoIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    label="No"
                   
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
                <Button
                    label="Yes"
                  
                    onClick={confirmDialog.onConfirm} />
            </DialogActions>
        </Dialog>
    )
}