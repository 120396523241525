//#region 
////<summary>
////Custom Tab Used in various places in the app 
//// Material-ui Appbar, Tabs are used in this
//// take props as input to render UI of Tab
////<Summary
//#endregion
import React from "react";
import PropTypes from 'prop-types';
import { makeStyles, AppBar, Box, Tabs as MuiTabs, Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected ": {
      opacity: 1,
      marginBottom: "0px",
      borderRadius: "0px",
    },
   
  },
  bgColor: {
   backgroundColor: theme.palette.secondary.main,
    margin:"0px",
    padding:"0px",
   
  },
 
}));
const Tabs = (props) => {
  const classes = useStyles();
  const {
    selectedTab,
    tabsLabel=()=>{},
    onTabChange=()=>{},
    variant,
    scrollButtons,
    ariaLabel,
    index,
    children,

    ...other
  } = props;

  return (
    <>
      <AppBar
        position="static"
        sm={12}
        elevation={0}
        color="transparent"
        className={classes.root}
      >
        <MuiTabs
          value={selectedTab}
          onChange={onTabChange}
          variant={variant || "scrollable"}
          scrollButtons={scrollButtons || "auto"}
          aria-label={ariaLabel || "myTab"}
          textColor="primary"
          indicatorColor="secondary"
        >
          {tabsLabel()}
        </MuiTabs>
      </AppBar>
      <Paper elevation={0}
        role="tabpanel"
        hidden={selectedTab !== index}
        id={`myTab-${index}`}
        aria-labelledby={`myTab-${index}`}
        {...other}
        className={classes.bgColor}
      >
        {selectedTab === index && <Box>{children}</Box>}
      </Paper>
    </>
  );
};
Tabs.propTypes = {
  children: PropTypes.node,
  selectedTab:PropTypes.any.isRequired,
  tabsLabel:PropTypes.func.isRequired,
  onTabChange:PropTypes.func.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default Tabs;
