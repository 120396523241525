//#region 
// <summary>
// Manage Routes and set pages/components for that routes
//</summary> 
//#endregion
import React from "react";
import loadable from '@loadable/component';
import { Navigate, Route, Routes } from "react-router-dom";
import AuthRequire from '../components/Authentication/AuthRequire';

import PageNotFound from "../components/Error/PageNotFound";

import UserRoutes from "./UserRoutes";
const Unauthorised =loadable(()=>import("../components/Unauthorized/Unauthorized"));

const LoginPage=loadable(()=>import("../pages/LoginPage"));
const Dashboard=loadable(()=>import("../pages/Dashboard"));
const ProfilePage=loadable(()=>import("../pages/ProfilePage"));
const ChangePassword=loadable(()=>import("../components/Profile/ChangePassword"));
const ProfileDetails =loadable(()=>import('../components/Profile/ProfileDetails'));
const Layout=loadable(()=>import("../components/Layout/Layout"));
const ForgetPassword=loadable(()=>import("../pages/ForgetPasswordPage"));
const ResetPassword=loadable(()=>import("../pages/ResetPasswordPage"));
const allowedRoleNames=["Admin","Partner","Auditor","Reviewer","User"];
const NavigationRoutesUser = () => {
  return (
    <Routes>
        <Route path="/pageNotFound" element={<PageNotFound/>} />
        <Route path="/unauthorised" element={<Unauthorised />} />
   
      <Route path="login" element={<LoginPage />}></Route>
      <Route path="forgotpassword" element={<ForgetPassword/>}></Route>
        <Route path="forgotpassword/:resetToken" element={<ResetPassword/>}></Route>
      <Route element={<AuthRequire allowedRoles={allowedRoleNames}  />}>
      <Route path="/" element={<Layout />}>
      <Route index element={<Dashboard />}></Route>
      <Route path='profile' element={<ProfilePage/>}>
      <Route index element={<ProfileDetails/>}></Route>
      <Route path='change-password' element={<ChangePassword/>}></Route>
      </Route>
      <Route path="/*" element={<UserRoutes />}></Route>
       
      </Route>

      </Route>
      <Route path="*" element={<Navigate replace to="/pageNotFound" />} />
    </Routes>
  );
};

export default NavigationRoutesUser;
