
import { withStyles, TableHead } from '@material-ui/core';
const StyledTableHead
 = withStyles((theme) => ({
    root: {
      // backgroundColor: theme.palette.common.gray.backgroundColor.backgroundColor,
      // color: theme.palette.primary.dark,
      '& .MuiTableRow-head':{
        borderTop:'none',
        borderBottom:'none',
        background:'#fff',
      }
    },
  }))(TableHead);
  

export default StyledTableHead

