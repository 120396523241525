// import { createContext, useState } from "react";

// const AuthContext = createContext({ auth: "", handleUser: () => {} });

// const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState("");
//   const handleUser = (user) => {
    
//     setAuth(user);
//   };
//   return (
//     <AuthContext.Provider value={{ auth, handleUser }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export { AuthContext, AuthProvider };

import { createContext, useCallback, useState } from "react";
import getUserSessionData from "../../hooks/getUserSessionData";
import jwtDecode from "jwt-decode";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const  {
        userName,
        roles,
        permissions,
        accessToken,
        userProfileImage,
        userCompanyImage,
        companyName,
        userId
      }=  getUserSessionData();
    const [auth, setAuth] = useState({ userName,
        roles,
        permissions,
        accessToken,
        userProfileImage,
        userCompanyImage,
        companyName,
        userId,
      });
        const handleAuth = useCallback((value) => {
            const sessionId = value?.sessionId ? value?.sessionId : ""; 
            //set sessionId session storage with sessionId
            sessionId && sessionStorage.setItem(`ars-audit-session`,sessionId);
            //set userProfileImage session storage with userProfileImage
            const userProfileImage= value?.userProfileImageBase64 ? value?.userProfileImageBase64 : value?.userProfileImage;
            const userProfileImageSessionKey = `${sessionId}-ProfileImage`;
            sessionId &&  sessionStorage.setItem(userProfileImageSessionKey,userProfileImage);
            //set userCompanyImage session storage with userCompanyImage
            const userCompanyImage=value?.userCompanyImageBase64 ? value?.userCompanyImageBase64 : value?.userCompanyImage;
            const userCompanyImageSessionKey = `${sessionId}-UserCompanyImage`;
            sessionId && sessionStorage.setItem( userCompanyImageSessionKey,userCompanyImage);
            // set local session storage of jwt token with key userId
            sessionId && sessionStorage.setItem(sessionId, value?.accessToken);
              //get access token from session storage
              const accessToken = value?.accessToken;
              //decoded token
              const decodedToken = accessToken ? jwtDecode(accessToken) : null;
              const userName = decodedToken?.userName;
              const roles = decodedToken?.role;
              const permissions = decodedToken?.Permissions;
              const companyName = decodedToken?.companyName;
              const userId=decodedToken?.userId;
            setAuth({
              userName,
              roles,
              permissions,
              accessToken,
              userProfileImage,
              userCompanyImage,
              companyName,
              userId,
            });
          });
    return (
        <AuthContext.Provider value={{ auth, handleAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
