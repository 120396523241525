import React from 'react'
import { Routes,Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import loadable from '@loadable/component';
import AuthRequire from '../components/Authentication/AuthRequire';
import { permissions } from "../components/Authentication/allPermissions";
const AuditReports=loadable(()=>import("../components/AuditReports/AuditReport"));
const NoObservationUploadPage =loadable(()=>import("../pages/NoObservationUploadPage"));
const AuditPage =loadable(()=>import("../pages/AuditPage"));
const AuditsPage =loadable(()=>import("../pages/AuditsPage"));
const PrncUpload =loadable(()=>import("../components/AuditPrnc/PrncUpload"));
const ComplianceUploadPage =loadable(()=>import("../pages/ComplianceUploadPage"));
const ManualCheckpoint =loadable(()=>import("../components/AuditCheckpoint/ManualCheckpoint"));
const AuditDocuments=loadable(()=> import("../components/AuditDocuments/AuditDocuments"));
const AuditPrnc =loadable(()=>import("../components/AuditPrnc/AuditPrnc"));
const AuditCompliances =loadable(()=>import("../components/AuditCompliances/AuditCompliances"));
const AuditUploads =loadable(()=>import("../components/AuditUploads/AuditUploads"));
const AuditDetails =loadable(()=>import("../components/Audit/AuditDetails"));
const ObservationViewPage =loadable(()=>import("../pages/ObservationViewPage"));
const ObserVationUpdatePage=loadable(()=>import("../pages/AuditCheckpointObservationUpdatePage"));
const CheckpointViewPage =loadable(()=>import('../pages/CheckpointViewPage'));
const AuditViewPage =loadable(()=>import("../pages/AuditViewPage"));
const Audit =loadable(()=>import("../components/Audit/Audit"));
const AuditCheckpoint =loadable(()=>import("../components/AuditCheckpoint/AuditCheckpoint"));
const AuditObservation =loadable(()=>import("../components/AuditObservation/AuditObservation"));
const CreateObservation =loadable(()=>import("../components/AuditObservation/CreateObservation"));
const ManualObservation =loadable(()=>import("../components/AuditObservation/ManualObservation"));
const ClientViewPage =loadable(()=>import("../pages/ClientViewPage"));
const ClientsPage =loadable(()=>import("../pages/ClientsPage"));
const ActionLogPage=loadable(()=>import("../pages/ActionLogPage"));
const allowedRoleNames=["Admin","Partner","Auditor","Reviewer","User"];

const UserRoutes = () => {
    const location=useLocation();

  return (
  
    <Routes   key={location.key} location={location}>
    <Route path="audit" element={<AuditsPage />}></Route>
    <Route path="client/:clientId" element={<ClientViewPage />}></Route>
    <Route path="client/" element={<ClientsPage />}></Route>
       <Route path="notifications" element={<ActionLogPage/>}></Route> 
       <Route element={<AuthRequire allowedPermissions={[permissions.CanViewAudit]}/>}>

        <Route path="audit/:auditId" element={<AuditViewPage />}></Route>
       </Route>
               <Route path="audit" element={<AuditPage/>}>
         <Route element={<AuthRequire allowedPermissions={[permissions.CanCreateAudit]} />}>
            <Route path="creation" element={<Audit/>}></Route>
            <Route path="creation/:auditId" element={<Audit/>}></Route>
          </Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanUploadAuditDocuments]} />}>
            <Route path="uploadDocs" element={<AuditUploads/>}></Route>
            <Route path="uploadDocs/:auditId" element={<AuditUploads/>}></Route>
          </Route>
            <Route path="prnc" element={<AuditPrnc/>}></Route>
            <Route path="prnc/:auditId" element={<AuditPrnc/>}></Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanFileUploadPRNC]} />}>
            <Route path="prnc/docs/:auditIdParam/:checkpointIdParam/:observationIdParam" element={<PrncUpload/>}></Route>
          </Route>
          <Route path="checkpoints" element={<AuditCheckpoint/>}></Route>
          <Route path="checkpoints/:auditId" element={<AuditCheckpoint/>}></Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanAddManualCheckpoint]} />}>
            <Route path="checkpoints/manual/:auditId" element={<ManualCheckpoint/>}></Route>
          </Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanAddManualCheckpoint]} />}>
            <Route path="checkpoints/manual/:auditId/:manualCheckpointId" element={<ManualCheckpoint/>}></Route>
          </Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanCreateObservation]}/>}>
            <Route path=":auditId/checkpoint/observation/:checkpointIdParam" element={<CreateObservation/>}></Route>
            <Route path="checkpoint/docs/:auditIdParam/:checkpointIdParam" element={<NoObservationUploadPage/>}></Route>
          </Route>
          <Route element={<AuthRequire allowedPermissions={[permissions.CanAddManualObservation]} />}>
            <Route path=":auditId/checkpoint/observation-manual/:checkpointIdParam" element={<ManualObservation/>}></Route>
          </Route>
        <Route element={<AuthRequire allowedPermissions={[permissions.CanViewAuditCheckpoint]} />}>
            <Route path="checkpoint/:auditIdParam/:checkpointIdParam" element={<CheckpointViewPage/>}></Route>
        </Route>
          
            <Route path="observations" element={<AuditObservation/>}></Route>
            <Route path="observations/:auditId" element={<AuditObservation/>}></Route>
            <Route element={<AuthRequire allowedPermissions={[permissions.CanCreateObservation]}/>}>
            <Route path="observation/:auditIdParam/:obsIdParam" element={<ObserVationUpdatePage/>}></Route>
        </Route>
        <Route element={<AuthRequire allowedPermissions={[permissions.CanViewObservation]}/>}>
            <Route path="observation/:auditIdParam/details/:observationIdParam" element={<ObservationViewPage/>}></Route>
        </Route>
            <Route path="compliances" element={<AuditCompliances/>}></Route>
            <Route path="compliances/:auditId" element={<AuditCompliances/>}></Route>
            <Route element={<AuthRequire allowedPermissions={[permissions.CanFileUploadCompliance]}/>}>
            <Route path="compliances/docs/:auditIdParam/:checkpointIdParam/:observationIdParam" element={<ComplianceUploadPage/>}></Route>
           </Route>           
            <Route path="documents" element={<AuditDocuments/>}></Route>
            <Route path="documents/:auditId" element={<AuditDocuments/>}></Route>
          
          <Route element={<AuthRequire allowedPermissions={[permissions.CanDownloadFinalReportPPT]} />}>
            <Route path="reports" element={<AuditReports/>}></Route>
            <Route path="reports/:auditId" element={<AuditReports/>}></Route>
            </Route>
         </Route>
        </Routes>
       
  )
}

export default UserRoutes