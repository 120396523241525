import {withStyles, TableBody } from "@material-ui/core";
const StyledTableBody
 = withStyles((theme) => ({
    root: {
        //its affects the border of cells
    //  backgroundColor: theme.palette.common.gray.backgroundColor.backgroundColor,
   
    },
  }))(TableBody);
  

export default StyledTableBody