import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles((theme)=>({
 
    controlLabel:{
      lineHeight:'100%',
      fontWeight:500,
    },
    error__label: {
      color: theme.palette.error.main,
    },
    customTextField: {
      "& input::placeholder": {
        fontSize: "14px",
        textTransform:"capitalize",
      },
      "& MuiFormHelperText-root Mui-error" :{
        textTransform:"capitalize",
      },
    

    },
    
  }));
export default function DatePicker(props) {
    const classes = useStyles();
    const { name, label, value, dateFormat, onChange,prompt, placeholder, disableFuture, disablePast, minDate,isRequiredLabel,...others } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl component="fieldset">
        {prompt && (
      <Typography className={classes.controlLabel} component={"label"}  variant="subtitle1"
    
       >           
        {prompt}
        {isRequiredLabel && <span className={classes.error__label}>*</span>}
      </Typography>
    
    )}
     <div className="formControl">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker  variant="outlined" inputVariant="outlined"
            clearable
                label={label}
                format={dateFormat || "dd/MM/yyyy"}
                name={name}
                value={value}
                onChange={date => onChange(convertToDefEventPara(name, date))}
                placeholder={placeholder}
               fullWidth
               disableFuture={disableFuture}
               disablePast={disablePast}
               minDate={minDate}
               autoOk={true}
               className={classes.customTextField}
             //  showTodayButton={true}
               {...others}
              //  InputProps={{
              //   endAdornment: (
              //     <IconButton onClick={ date => onChange(convertToDefEventPara(name, null))}>
              //       <ClearIcon />
              //     </IconButton>
              //   )
              // }}
              // InputAdornmentProps={{
              //   position: "start"
              // }}
               inputProps={{
                readOnly:true,
              }}
            />
        </MuiPickersUtilsProvider>
        </div>
        </FormControl>
    )
}